/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

const navSlide = () => {
    const burger = document.querySelector('.burger');
    const nav = document.querySelector('.nav-links');
    const head = document.querySelector('.nav-logo');
    const navLinks = document.querySelectorAll('.nav-links li');
  
    burger.addEventListener('click', () => {
    nav.classList.toggle('nav-active');
    head.classList.toggle('logo-active');
    burger.classList.toggle('burger-active');
    navLinks.forEach((link, index) => {
      if (link.style.animation){
        link.style.animation = '';
      } else {
          link.style.animation = `navLinkFade 0.5s ease forwards ${index / 7 + 0.5}s`;
        }
      });
    
      burger.classList.toggle('toggle');
    
      });
  }
    
   
exports.onRouteUpdate = () => {
    navSlide();
  };